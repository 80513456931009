import React, { useEffect, useState } from "react";
import { useStyles } from "./Style.js";
import {
  getAllVerifiedReelsByShopId,
  deleteReels,
} from "../../../API/ReelsApi.js";
import { Link, useParams } from "react-router-dom";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import empty from "../../Elements/Images/empty1.gif";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions.js";

function ShopReels() {
  const classes = useStyles();
  const [reels, setReels] = useState([]);
  const { shopId } = useParams();
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const fetchData = () => {
    handleLoading(true);
    getAllVerifiedReelsByShopId(shopId)
      .then((response) => {
        if (response.status === false) {
          handleLoading(false);
        } else {
          handleLoading(false);
        }
        return response;
      })
      .then((data) => {
        setReels(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [shopId]);

  const handleDelete = (reelId) => {
    handleLoading(true);
    deleteReels(reelId)
      .then(() => {
        setReels((prevReels) =>
          prevReels.filter((reel) => reel._id !== reelId)
        );
        handleLoading(false);
        toast.success("Reel deleted successfully");
      })
      .catch((error) => {
        handleLoading(false);
        toast.error(error.message);
        console.error("Error deleting reel:", error);
      });
  };

  const handlePlay = (e) => {
    e.preventDefault();
    const video = e.target;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "inlineFlex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}>
      {reels.length > 0 ? (
        <>
          <Box>
            <Typography
              align="center"
              marginTop="4vh"
              fontWeight="600"
              variant="h3"
              style={{ zIndex: "3" }}>
              Shop Reels
            </Typography>
            <Box align="right" margin="3vh">
              <Link
                to={`/admin/verifiedShopsList/addShopReels/${shopId}`}
                style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  style={{ color: "white" }}
                  margin="20px">
                  Add Some
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            height="auto"
            marginTop="10vh"
            marginInline="10vh"
            flexWrap="wrap"
            justifyContent="center">
            {reels.map((reel) => (
              <Box
                key={reel._id}
                position="relative"
                style={{
                  padding: "20px",
                  margin: "20px",
                  width: "320px",
                  boxShadow: "10px 10px 5px 5px #e1e1e1",
                  border: "2px #e1e1e1",
                  position: "relative",
                }}>
                <IconButton
                  onClick={() => handleDelete(reel._id)}
                  sx={{
                    position: "absolute",
                    top: 6,
                    right: 6,
                    zIndex: 1,
                    backgroundColor: "white",
                    color: "red",
                  }}>
                  <CloseIcon />
                </IconButton>
                <video
                  className={classes.videoStyles}
                  onClick={handlePlay}
                  controls
                  type="video/mp4"
                  autoPlay
                  muted>
                  <source src={reel.url} type="video/mp4" />
                </video>
              </Box>
            ))}
          </Box>
        </>
      ) : (
        <Box
          display="inlineFlex"
          alignItems="center"
          height="auto"
          justifyContent="center">
          <Box>
            <Typography
              align="center"
              marginBottom="170px"
              fontWeight="600"
              variant="h3"
              style={{ zIndex: "3" }}>
              Shop Reels
            </Typography>
            <Box display="flex" justifyContent="center">
              <img src={empty} width="500px" alt="No reels available" />
            </Box>
            <Box align="center">
              <Link
                to={`/admin/verifiedShopsList/addShopReels/${shopId}`}
                style={{ textDecoration: "none" }}>
                <Button variant="contained" style={{ color: "white" }}>
                  Add Some
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ShopReels;
