import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField, Modal, Typography } from "@mui/material";
import { getProductCategories, editCategory } from "../../../API/CategoryApi";
import SubCategoryList from "./SubCategoryList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CategoryOptions from "./CategoryOption/CategoryOptions";
import DeleteCategory from "../Delete/DeleteCategory";
import { Container } from "@mui/system";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";

export default function Categories() {
  const [category, setCategory] = useState([]);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    handleLoading(true);
    try {
      const data = await getProductCategories();
      if (data.status === true) {
        handleLoading(false);
        setCategory(data.data);
      } else {
        handleLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Fetch category error:", error);
    }
  };

  const handleEditClick = (cat) => {
    setEditCategoryData(cat);
    console.log(cat);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditCategoryData(null);
    setFile(null); // Reset the file state
  };

  const handleInputChange = (e) => {
    setEditCategoryData({
      ...editCategoryData,
      [e.target.name]: e.target.value,
    });
  };

  const uploadFile = async (e) => {
    if (e.target.files.length && e.target.files[0].size <= 3 * 1024 * 1024) {
      setFile({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    } else toast.warning("Image is too large, please keep it below 3MB");
  };

  const handleSaveChanges = async () => {
    handleLoading(true);
    if (editCategoryData) {
      try {
        // Prepare FormData for submission
        const formData = new FormData();
        formData.append("_id", editCategoryData._id);
        formData.append("name", editCategoryData.name);
        if (file) {
          formData.append("img", file.raw);
        }
        // Send the FormData to your API
        const data = await editCategory(formData, editCategoryData._id);
        if (data.status === true) {
          handleLoading(false);
        } else {
          handleLoading(false);
        }
        // Refresh the category list
        fetchCategories();
        handleModalClose();
      } catch (error) {
        console.error("Update category error:", error);
      }
    }
  };

  return (
    <Box>
      <Box
        sx={{
          "& .MuiTableCell-root": { width: "90%", margin: "auto" },
        }}>
        <h1 className="head" style={{ color: "#1e2328" }}>
          Categories
        </h1>
        <CategoryOptions />
        {category.length > 0 && (
          <TableContainer component={Paper}>
            <Container maxWidth="xl">
              <Table size="large">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                      Category Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Edit</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {category.map((cat) => (
                    <TableRow key={cat._id}>
                      <TableCell style={{ display: "flex" }}>
                        <img
                          src={cat.thumbnail}
                          width="30px"
                          height="30px"
                          style={{ borderRadius: "15px" }}
                          alt=""
                        />
                        <TreeView
                          aria-label="file system navigator"
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          sx={{ flexGrow: 1 }}>
                          <TreeItem nodeId="1" label={cat.name}>
                            <TreeItem
                              nodeId="2"
                              label={<SubCategoryList id={cat._id} />}
                            />
                          </TreeItem>
                        </TreeView>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleEditClick(cat)}>
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        <DeleteCategory variant="text" id={cat._id} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Container>
          </TableContainer>
        )}
      </Box>

      {/* Edit Category Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="edit-category-title"
        aria-describedby="edit-category-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography id="edit-category-title" variant="h6" component="h2">
            Edit Category
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Category Name"
            name="name"
            value={editCategoryData ? editCategoryData.name : ""}
            onChange={handleInputChange}
          />
          <input type="file" accept="image/*" onChange={uploadFile} />
          <img
            src={file ? file?.preview : editCategoryData?.thumbnail}
            width="60px"
            height="60px"
            style={{ borderRadius: "55px" }}
            alt=""
          />
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleModalClose}
              sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
