import { Button } from "@mui/material";
import React from "react";
import { deleteCategory } from "../../../API/CategoryApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";

const Delete = (props) => {
  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };
  const erase = () => {
    handleLoading(true);
    deleteCategory(props.id)
      .then((response) => {
        if (response.status === true) {
          handleLoading(false);
          toast.success(`${response.message}`);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(`${response.message}`);
        }
      })
      .catch((error) => {
        console.error("Error deleting category:", error);
      });
  };

  return (
    <Button onClick={() => erase()} variant={props.variant} color="error">
      Delete
    </Button>
  );
};

export default Delete;
