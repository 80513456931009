import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import Carousel from "../carousel/Caraousal";
import { Button, IconButton } from "@mui/material";
import {
  deleteProduct,
  updateProduct,
  verifyProduct,
} from "../../../API/ProductApi";
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/actions/actions";

const UnVerifiedProductCard = (props) => {
  const { imgUrl, Product_Name, Product_Desc, Price, id, productThumbnail } =
    props;

  const dispatch = useDispatch();

  const handleLoading = (value) => {
    dispatch(setLoading(value));
  };

  const verified = () => {
    handleLoading(true);
    const formData = new FormData();
    formData.append("isVerified", true);

    verifyProduct(props.id, formData)
      .then((response) => {
        return response;
      })
      .then((data) => {
        if (data.status === true) {
          handleLoading(false);
          toast.success(data.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        } else {
          handleLoading(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const erase = async () => {
    handleLoading(true);
    deleteProduct(id)
      .then((res) => {
        if (res.status == false) {
          handleLoading(false);
          toast.error(res.message);
        } else {
          handleLoading(false);
          toast.success(res.message);
          setTimeout(() => {
            window.location.reload();
          }, 2000); // Delay reload by 2 seconds
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  function truncateDescription(description, limit) {
    if (description.length <= limit || description.length <= 30) {
      return description;
    } else {
      return description.substring(0, limit) + "...";
    }
  }

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <>
      <Card
        sx={{
          width: "320px",
          height: "520px",
          m: 3,
          p: 0,
          boxShadow: "10px 10px 5px 5px #e1e1e1",
          border: "2px #e1e1e1",
          position: "relative", // To position delete button
        }}>
        <IconButton
          onClick={() => erase()} // Call onDelete function with the product ID
          sx={{
            position: "absolute",
            top: 6,
            right: 6,
            zIndex: 1,
            color: "red", // Set the color to red
          }}>
          <CloseIcon />
        </IconButton>

        <Box align="center" height="330px" m="1vh">
          {typeof imgUrl !== "string" && imgUrl.length > 0 ? (
            <Carousel images={imgUrl} shopId={props.shopId} id={props.id} />
          ) : typeof imgUrl === "string" ? (
            <Link
              to={`/admin/verifiedShopsList/showProducts/${props.shopId}/${props.id}`}
              style={{ textDecoration: "none" }}>
              <img src={imgUrl} alt="No Image" width="300px" height="300px" />
            </Link>
          ) : (
            <Link
              to={`/admin/verifiedShopsList/showProducts/${props.shopId}/${props.id}`}
              style={{ textDecoration: "none" }}>
              <img src="/no-image.jpg" alt="No Image" />
            </Link>
          )}
        </Box>
        <Box align="center" p="1vh" display="grid">
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              fontFamily: "inter",
              lineHeight: "30px",
              color: "grey",
            }}>
            {truncateDescription(Product_Name, 20)}
          </Typography>
          <Box maxHeight="8vh" overflow="hidden">
            <Typography>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  truncateDescription(Product_Desc, 60)
                )}
              />
            </Typography>
          </Box>
          <Typography
            color="text.secondary"
            component="div"
            fontSize="12px"
            sx={{ mt: 1 }}>
            <b>Price:</b> {Price}
          </Typography>
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckIcon />}
            mt="10px"
            onClick={() => verified()}>
            Verify
          </Button>
        </Box>
      </Card>
    </>
  );
};
export default UnVerifiedProductCard;
