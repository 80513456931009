/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from "@mui/material";
import UnVerifiedShopProduct from "./UnVerifiedShopProduct";
import { Link, useParams } from "react-router-dom";
import ProductList from "./ProductList";
import Banner from "./Banner";
import InventoryIcon from "@mui/icons-material/Inventory";
import PagesIcon from "@mui/icons-material/Pages";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { SideBarWidth } from "../../constants";
import { getShopById } from "../../API/ShopApi";
import { Grid } from "@mui/material";
import ShopReels from "./Reels/ShopReels";
import { PlayArrow } from "@mui/icons-material";

export default function VerifiedShopTemplate(props) {
  const [user, setUser] = useState({
    name: "",
    kartaName: "",
    aadharNo: "",
    panNo: "",
    gstNo: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    location: [],
    shopThumbnail: "",
    shopBgThumbnail: "",
  });

  const { shopId } = useParams();

  const fetchData = async () => {
    await getShopById(shopId)
      .then((response) => {
        return response;
      })
      .then((data) => {
        setUser(data.data);
        console.log(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [value, setValue] = React.useState("productList");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);
  // const img = http://api.litchies.com/images/${user.shopImg};

  return (
    <>
      <Box sx={{ height: "fit-content", align: "center" }}>
        <Box
          sx={{
            height: "50vh",
            backgroundImage: `url(${user.shopBgImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          {user.shopBgThumbnail}
        </Box>
        <Link to={`/admin/verifiedShopsList/${shopId}`}>
          <Box
            sx={{
              height: "300px",
              width: "300px",
              backgroundColor: "crimson",
              position: "absolute",
              zIndex: 2,
              marginLeft: "5%",
              borderRadius: "50%",
              top: "30%",
              left: "40%",
            }}>
            <Box
              sx={{
                height: "290px",
                width: "290px",
                backgroundImage: `url(${user.shopThumbnail})`,
                position: "absolute",
                zIndex: 3,
                marginLeft: "5%",
                borderRadius: "50%",
                top: "1.5%",
                backgroundPosition: "center",
                backgroundSize: "cover",
                left: "-3.5%",
              }}></Box>
          </Box>
        </Link>
        <Grid container spacing={2} sx={{ marginLeft: `${SideBarWidth}` }}>
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h3"
              sx={{ marginTop: "20vh" }}>
              {user.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.name && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Shop Name:{" "}
              <Typography variant="p" color="black">
                {user.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.name && "none"}` }}>
            <Typography fontSize="22px" my="2vh" color="grey" width="80vh">
              Shop Description:{" "}
              <Typography variant="p" color="black">
                {user.desc}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.kartaName && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Karta Name:{" "}
              <Typography variant="p" color="black">
                {user.kartaName}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.email && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              E-mail:{" "}
              <Typography variant="p" color="black">
                {user.email}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.aadharNo && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Aadhar No.:{" "}
              <Typography variant="p" color="black">
                {user.aadharNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.address && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Address:{" "}
              <Typography variant="p" color="black">
                {user.address}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.gstNo && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Gst No.:{" "}
              <Typography variant="p" color="black">
                {user.gstNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.mobile && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Mobile:{" "}
              <Typography variant="p" color="black">
                {user.mobile}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.panNo && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Pan No.:{" "}
              <Typography variant="p" color="black">
                {user.panNo}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.city && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              City:{" "}
              <Typography variant="p" color="black">
                {user.city}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.state && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              State:{" "}
              <Typography variant="p" color="black">
                {user.state}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: "center", display: `${!user.pincode && "none"}` }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Pincode:{" "}
              <Typography variant="p" color="black">
                {user.pincode}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "center",
              display: `${!user.location && "none"}`,
            }}>
            <Typography fontSize="22px" my="1vh" color="grey">
              Coordinates:{" "}
              <Typography variant="p" color="black">
                {user.location}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} paddingTop="2vh">
          <Box width="100%" align="center">
            <BottomNavigation
              sx={{ width: 500 }}
              value={value}
              onChange={handleChange}>
              <BottomNavigationAction
                label="Unverified Products"
                value="un-verifiedProducts"
                icon={<UnpublishedIcon />}>
                {" "}
              </BottomNavigationAction>
              <BottomNavigationAction
                label="Product List"
                value="productList"
                icon={<InventoryIcon />}></BottomNavigationAction>
              <BottomNavigationAction
                label="Banner"
                value="banner"
                icon={<PagesIcon />}></BottomNavigationAction>
              <BottomNavigationAction
                label="Shop Reels"
                value="ShopReels"
                icon={<PlayArrow />}></BottomNavigationAction>
            </BottomNavigation>
          </Box>
          <Box
            display={value === "un-verifiedProducts" ? "visible" : "none"}
            title="Un-Verified Products"
            ease>
            <UnVerifiedShopProduct />
          </Box>
          <Box
            display={value === "productList" ? "visible" : "none"}
            title="ProductList">
            <ProductList />
          </Box>
          <Box display={value === "banner" ? "visible" : "none"} title="Banner">
            <Banner />
          </Box>
          <Box
            title="ShopReels"
            display={value === "ShopReels" ? "visible" : "none"}>
            <ShopReels />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
